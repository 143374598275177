export const getNewYPosition = (
  droppableElement: HTMLElement,
  itemIndex: number,
  rawY: number,
  areaHeight: number,
  areaTopOffset: number,
) => {
  const droppableTop = droppableElement.offsetTop;
  let itemOffsetY = 0;

  for (let i = 0; i < itemIndex; i++) {
    itemOffsetY += droppableElement.children[i].clientHeight;
  }

  const itemHeight = droppableElement.children[itemIndex].clientHeight;

  const maxY =
    areaHeight - (areaTopOffset - droppableTop) - itemHeight - itemOffsetY;

  return Math.min(Math.max(rawY, -1 * itemOffsetY), maxY);
};
