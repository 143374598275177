export const hideCookiebotTrigger = () => {
  const cookiebotTrigger = document.getElementById('CookiebotWidget');
  if (cookiebotTrigger) {
    cookiebotTrigger.style.display = 'none';
  }
};

export const showCookiebotTrigger = () => {
  const cookiebotTrigger = document.getElementById('CookiebotWidget');
  if (cookiebotTrigger) {
    cookiebotTrigger.style.display = 'block';
  }
};
