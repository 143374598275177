import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { ReactNode } from 'react';

interface StyledScrollWrapperProps {
  children: ReactNode;
}

export const StyledScrollWrapper = ({ children }: StyledScrollWrapperProps) => {
  return (
    <OverlayScrollbarsComponent
      style={{ width: '100%' }}
      options={{ overflow: { x: 'hidden', y: 'scroll' } }}
    >
      {children}
    </OverlayScrollbarsComponent>
  );
};
