import { HStack, Stack } from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-router';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledScrollWrapper } from '$/components/common/StyledScrollWrapper';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { CollectionFilter } from '$/components/core/Collection/CollectionFilter';
import { MaterialDetailsModal } from '$/components/core/Collection/MaterialDetailsModal';
import { useMaterialDetailStore } from '$/components/core/Collection/MaterialDetailsModal/store/useMaterialDetailStore';
import { DataSortingRow } from '$/components/core/Collection/MaterialPresentation/DataSortingRow';
import { MaterialRow } from '$/components/core/Collection/MaterialRow';
import { SearchDropdown } from '$/components/core/Collection/MaterialSearch/SearchDropdown';
import { useIsMobile } from '$/hooks/useIsMobile';
import { BackToTopButton } from '$/pages/DashboardPages/components/BackToTopButton';
import { DashboardSectionRow } from '$/pages/DashboardPages/components/DashboardSectionRow';
import { CollectionHeader } from '$/pages/DashboardPages/pages/Collection/components/CollectionHeader';
import { FavoriteModal } from '$/pages/DashboardPages/pages/Favorite/components/FavoriteModal';
import { MaterialType } from '$/services/mapper/uses';
import { useCollectionStore } from '$/stores/useCollectionStore';

export const CollectionDashboard = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const user = useAuthenticationStore.useUser();
  const setHasUser = useCollectionStore.useSetHasUser();

  useEffect(() => {
    void setHasUser(user?.id != null);
  }, [setHasUser, user?.id]);

  const sectionRef = useRef<HTMLDivElement>(null);

  const setActiveMaterial = useMaterialDetailStore.useSetActive();

  const [materialCount, setMaterialCount] = useState<
    Record<string, number | null>
  >({
    uni: null,
    wall: null,
    floor: null,
  });

  const findSingleMaterial = (materials: Record<string, number | null>) => {
    let foundMaterial = null;

    for (const [type, count] of Object.entries(materials)) {
      if (count === null) {
        return null;
      }

      if (count > 0 && !foundMaterial) {
        foundMaterial = type;
      } else if (count > 0) {
        return null;
      }
    }

    return foundMaterial;
  };

  const onMaterialResult = useCallback<
    (count: number, type: MaterialType) => void
  >(
    (count, type) => {
      setMaterialCount((prev) => ({ ...prev, [type]: count }));
    },
    [setMaterialCount],
  );

  const navigate = useNavigate();

  useEffect(() => {
    const singleMaterial = findSingleMaterial(materialCount);

    switch (singleMaterial) {
      case 'uni':
        void navigate({ to: '/collections/colors' });
        return;
      case 'wall':
        void navigate({ to: '/collections/walls' });
        return;
      case 'floor':
        void navigate({ to: '/collections/floors' });
        return;
      case 'facade':
        void navigate({ to: '/collections/facades' });
        return;
      default:
        return;
    }
  }, [materialCount, navigate]);

  return (
    <>
      <MaterialDetailsModal />
      <Stack
        ref={sectionRef}
        w='full'
        {...(!isMobile && {
          layerStyle: 'DashboardSection',
        })}
      >
        <StyledScrollWrapper>
          <Stack
            gap='0'
            overflowY='auto'
            w='full'
            {...(!isMobile && {
              p: '7',
            })}
          >
            <HStack
              align='flex-start'
              justify='space-between'
              flexWrap={{ base: 'wrap', mobile: 'nowrap' }}
              gap={{ base: '3', mobile: '4' }}
              mb='2'
            >
              <CollectionHeader />
              <SearchDropdown
                maxW='275px'
                w='100%'
                pb={{ base: '4', lg: '0' }}
                searchLabel={t('dashboard.collection.searchColors')}
                onResultClick={(material) => setActiveMaterial(material)}
              />
            </HStack>

            <CollectionFilter
              sectionRef={sectionRef}
              type='all'
              amount={
                Object.values(materialCount).reduce(
                  (sum, value) => (sum ?? 0) + (value ?? 0),
                  0,
                ) ?? 0
              }
            />

            <DataSortingRow
              isLoading={Object.values(materialCount).every((count) => !count)}
              hideCount
              scrollRefs={[sectionRef]}
            />
            <Stack gap='6' pt='6'>
              <MaterialRow
                type='uni'
                showAllUrl='/collections/colors'
                headline={t('dashboard.collection.search.colorTones')}
                onResult={onMaterialResult}
                wrapperRef={sectionRef}
                RowWrapper={DashboardSectionRow}
                onSelect={setActiveMaterial}
              />
              <MaterialRow
                type='wall'
                showAllUrl='/collections/walls'
                headline={t('dashboard.collection.search.wallCoverings')}
                onResult={onMaterialResult}
                wrapperRef={sectionRef}
                RowWrapper={DashboardSectionRow}
                onSelect={setActiveMaterial}
              />
              <MaterialRow
                type='floor'
                showAllUrl='/collections/floors'
                headline={t('dashboard.collection.search.floorCoverings')}
                onResult={onMaterialResult}
                wrapperRef={sectionRef}
                RowWrapper={DashboardSectionRow}
                onSelect={setActiveMaterial}
              />
              <MaterialRow
                type='facade'
                showAllUrl='/collections/facades'
                headline={t('dashboard.collection.facades')}
                onResult={onMaterialResult}
                wrapperRef={sectionRef}
                RowWrapper={DashboardSectionRow}
                onSelect={setActiveMaterial}
              />
            </Stack>
            <BackToTopButton
              containerRef={sectionRef}
              right='65px'
              bottom='65px'
            ></BackToTopButton>
          </Stack>
        </StyledScrollWrapper>
      </Stack>
      <FavoriteModal />
    </>
  );
};
