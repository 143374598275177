import { Stack } from '@chakra-ui/react';
import { useRef } from 'react';

import { StyledScrollWrapper } from '$/components/common/StyledScrollWrapper';
import { BackToTopButton } from '$/pages/DashboardPages/components/BackToTopButton';
import { DashboardSectionRow } from '$/pages/DashboardPages/components/DashboardSectionRow';
import { ProjectGridItems } from '$/pages/DashboardPages/pages/Projects/components/ProjectGridItems';
import { ProjectHeader } from '$/pages/DashboardPages/pages/Projects/components/ProjectHeader';
import { ProjectModal } from '$/pages/DashboardPages/pages/Projects/components/ProjectModal';
import { useProjectStore } from '$/stores/useProjectStore';

export const ProjectPage = () => {
  const ref = useRef<HTMLDivElement>(null);
  const projects = useProjectStore.useProjects();

  return (
    <Stack ref={ref} layerStyle='DashboardSection' w='full'>
      <StyledScrollWrapper>
        <Stack gap='6' overflowY='auto' w='full' p='7'>
          <ProjectHeader />
          <DashboardSectionRow>
            <ProjectGridItems items={projects} />
          </DashboardSectionRow>
          <ProjectModal />

          <BackToTopButton
            containerRef={ref}
            right='65px'
            bottom='65px'
          ></BackToTopButton>
        </Stack>
      </StyledScrollWrapper>
    </Stack>
  );
};
