import { t } from 'i18next';
import { object, string, number, array, z, union, literal } from 'zod';

import { mapUsesToType } from '$/services/mapper/uses';
import { MaterialSchema } from '$/services/usecases/materials/mapper/materials';
import { unsecureSeededRandom } from '$/utils/numberUtils';

export type ComponentCategory =
  | 'floor'
  | 'wall'
  | 'ceiling'
  | 'outside'
  | 'unknown';

const mapNameToCategory = (internationalName: string): ComponentCategory => {
  if (internationalName.includes('wall')) return 'wall';
  if (internationalName.includes('floor')) return 'floor';
  if (internationalName.includes('ceiling')) return 'ceiling';
  if (
    internationalName.includes('facade') ||
    internationalName.includes('roof')
  )
    return 'outside';
  return 'unknown';
};

export const CompleteSceneSchema = object({
  status: union([literal(1), literal(0)]),
  scenes: array(
    object({
      id: string(),
      name: string(),
      trans_name: string(),
      category: string(),
      category_master: string(),
      view_cnt: number(),
      views: array(
        object({
          view_nr: string(),
          view_id: string(),
          objects: array(
            object({
              id: string(),
              trans_name: string(),
              int_name: string(),
              width: number(),
              height: number(),
              uses: array(string()),
              initial_material: MaterialSchema.nullable(),
            }),
          ),
        }),
      ),
    }),
  ).optional(),
}).transform(
  (data) =>
    data.scenes?.map((scene) => ({
      name: t(`inspirationEditor.scenes.${scene.name}`, {
        defaultValue: scene.name,
      }),
      nameKey: scene.name,
      id: scene.id,
      category: scene.category,
      categoryId: scene.category,
      categoryMaster: scene.category_master,
      viewCount: scene.view_cnt,
      clicks: Math.floor(unsecureSeededRandom(parseInt(scene.id)) * 100000),
      views: scene.views.map((view) => ({
        viewNr: view.view_nr,
        viewId: view.view_id,
        components: view.objects.map((component) => ({
          id: component.id,
          category: mapNameToCategory(component.int_name),
          materialCategoryOptions: component.uses,
          materialTypes: component.uses.flatMap(mapUsesToType),
          name: component.trans_name,
          material: component.initial_material
            ? component.initial_material
            : null,
          uniqueName: component.int_name,
          position: {
            x: component.width,
            y: component.height,
          },
        })),
      })),
    }))[0] ?? null,
);

export type CompleteScene = z.infer<typeof CompleteSceneSchema>;
