import { HttpBackendOptions } from 'i18next-http-backend';
import { FC, ReactElement } from 'react';

import { i18n } from '$/components/core/Localization/18n';

interface Props {
  children: ReactElement;
}

export const LocalizationProvider: FC<Props> = ({ children }) => {
  if (!i18n.isInitialized) {
    void i18n.init<HttpBackendOptions>({
      debug: true,
      fallbackLng: 'en',
      detection: {
        order: ['localStorage', 'navigator'],
        caches: ['localStorage'],
      },
      backend: {
        loadPath: `${import.meta.env.VITE_LANGUAGE_STORAGE_URL}{{lng}}.json`,
        parse: (data) => {
          const parsedData = JSON.parse(data) as Record<string, unknown>;

          if (parsedData.translation != null) {
            return parsedData.translation;
          }
          return parsedData;
        },
      },
    });
  }

  return children;
};
