import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  MenuItem,
  Text,
  HStack,
  Portal,
  MenuGroup,
} from '@chakra-ui/react';
import { Link } from '@tanstack/react-router';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Show } from '$/components/common/Flow/Show';
import { GhostButton } from '$/components/common/GhostButton';
import { Icon } from '$/components/common/Icon';
import { ThemeSwitcher } from '$/components/common/ThemeSwitcher';
import { StyledLink } from '$/components/core/StyledLink';
import { trackContentImpression, trackContentInteraction } from '$/logger';
import { useCollectionStore } from '$/stores/useCollectionStore';
import { LanguageOptions, useGeneralStore } from '$/stores/useGeneralStore';
import { LanguageOption } from '$/utils/piwikUtils';
interface Props {
  height?: string;
  showHomeLink?: boolean;
}

export const BrandMenu: FC<Props> = ({ height, showHomeLink = true }) => {
  const { t, i18n } = useTranslation();
  const selectedLanguage = useGeneralStore.useSelectedLanguage();
  const setSelectedLanguage = useGeneralStore.useSetSelectedLanguage();
  const refetchFilterOptions = useCollectionStore.useRefetchFilterOptions();

  const availableLanguages = ['en', 'de'] as LanguageOptions[];

  const [isOpen, setIsOpen] = useState(false);
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleMouseEnter = () => {
    setIsLanguageMenuOpen(true);
  };

  const handleMouseLeave = () => {
    setIsLanguageMenuOpen(false);
  };

  const isLangSelected = (lang: string) => {
    return selectedLanguage === lang;
  };

  const onChangeLanguage = async (language: LanguageOptions) => {
    await i18n.changeLanguage(language);
    setSelectedLanguage(language);
    localStorage.setItem('userLanguage', language);
    setIsOpen(false);
    setIsLanguageMenuOpen(false);
    await refetchFilterOptions();
  };

  return (
    <Menu
      closeOnSelect={false}
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
    >
      {({ isOpen }) => (
        <>
          <Flex align='center' h='full'>
            <Link to='/'>
              <Icon icon='caparol' width='28px' height='26px' />
            </Link>
            <MenuButton
              as={GhostButton}
              h={height}
              color='header.text'
              fontSize='sm'
              onClick={() => {
                trackContentImpression('MainMenu', 'BrandMenu', 'Show');
              }}
              rightIcon={
                <Icon
                  transition='transform 0.3s ease'
                  transform={`rotate(${isOpen ? '180deg' : '0deg'})`}
                  icon='chevron_down'
                  w='12px'
                />
              }
            >
              {t('brand.spectrum')}
            </MenuButton>
          </Flex>

          <Portal>
            {isOpen && (
              <MenuList fontSize='sm' borderColor='border'>
                <Show when={showHomeLink}>
                  <MenuItem>
                    <StyledLink link={{ to: '/' }} w='full'>
                      {t('dashboard.header.toStart')}
                    </StyledLink>
                  </MenuItem>
                  <MenuDivider />
                </Show>

                <MenuItem
                  as='div'
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <Menu
                    autoSelect={false}
                    isOpen={isLanguageMenuOpen}
                    offset={[0, 12]}
                    onClose={() => setIsLanguageMenuOpen(false)}
                    onOpen={() => setIsLanguageMenuOpen(true)}
                    placement='right'
                  >
                    <MenuButton
                      zIndex={999}
                      w='full'
                      textAlign='left'
                      borderRadius='none'
                      onClick={() => {
                        trackContentInteraction(
                          'ClickButton',
                          'MainMenu',
                          'Languages',
                          'Choose',
                        );
                        trackContentImpression(
                          'MainMenu',
                          'ChooseLanguage',
                          'Open',
                        );
                      }}
                    >
                      <HStack justifyContent={'space-between'}>
                        <Text>{t('dashboard.header.languages')}</Text>

                        <Icon
                          transform={`rotate(270deg)`}
                          icon='chevron_down'
                          w='12px'
                        />
                      </HStack>
                    </MenuButton>

                    <MenuList
                      zIndex={999}
                      w='full'
                      mt='50px'
                      borderColor='border'
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      {availableLanguages.map((lang) => (
                        <MenuItem
                          key={lang}
                          onClick={async () => {
                            await onChangeLanguage(lang);
                            trackContentInteraction(
                              'ClickButton',
                              'MainMenu',
                              'ChooseLanguage',
                              t(`languages.${lang}`, {
                                lng: 'en',
                              }) as LanguageOption,
                            );
                          }}
                        >
                          <HStack>
                            <Icon
                              icon='check_icon'
                              w='12px'
                              visibility={
                                isLangSelected(lang) ? 'visible' : 'hidden'
                              }
                            />
                            <Text>{t(`languages.${lang}`)}</Text>
                          </HStack>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </MenuItem>
                <MenuItem justifyContent='space-between' gap='3'>
                  <Box as='span'>{t('dashboard.header.theme')}</Box>
                  <Box h='32px' p='0'>
                    <ThemeSwitcher />
                  </Box>
                </MenuItem>
                {import.meta.env.VITE_NODE_ENV !== 'production' && (
                  <>
                    <MenuDivider />
                    <MenuGroup ml='3' title='Dokumentation'>
                      <MenuItem>
                        <Link to='/docs/api'>API Dokumentation</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to='/docs/technical'>
                          Technische Dokumentation
                        </Link>
                      </MenuItem>
                    </MenuGroup>
                  </>
                )}
                <MenuDivider />
                <MenuGroup title={t('dashboard.legal.title')}>
                  <MenuItem>
                    <StyledLink
                      link={{ to: '/imprint' }}
                      w='full'
                      onClick={() =>
                        trackContentInteraction(
                          'ClickButton',
                          'MainMenu',
                          'Imprint',
                          'Open',
                        )
                      }
                    >
                      {t('dashboard.legal.imprint')}
                    </StyledLink>
                  </MenuItem>
                  <MenuItem>
                    <StyledLink
                      link={{ to: '/integrity' }}
                      w='full'
                      onClick={() =>
                        trackContentInteraction(
                          'ClickButton',
                          'MainMenu',
                          'Integrity',
                          'Open',
                        )
                      }
                    >
                      {t('dashboard.legal.integrity')}
                    </StyledLink>
                  </MenuItem>
                  <MenuItem>
                    <StyledLink
                      link={{ to: '/privacy' }}
                      w='full'
                      onClick={() =>
                        trackContentInteraction(
                          'ClickButton',
                          'MainMenu',
                          'Privacy',
                          'Open',
                        )
                      }
                    >
                      {t('dashboard.legal.privacy')}
                    </StyledLink>
                  </MenuItem>
                  <MenuItem>
                    <StyledLink
                      link={{ to: '/tos' }}
                      w='full'
                      onClick={() =>
                        trackContentInteraction(
                          'ClickButton',
                          'MainMenu',
                          'Terms',
                          'Open',
                        )
                      }
                    >
                      {t('dashboard.legal.terms')}
                    </StyledLink>
                  </MenuItem>
                </MenuGroup>
              </MenuList>
            )}
          </Portal>
        </>
      )}
    </Menu>
  );
};
