import { Box, HStack, Stack } from '@chakra-ui/react';
import { ReactNode, forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledScrollWrapper } from '$/components/common/StyledScrollWrapper';
import { useMaterialDetailStore } from '$/components/core/Collection/MaterialDetailsModal/store/useMaterialDetailStore';
import { MaterialPresentation } from '$/components/core/Collection/MaterialPresentation';
import { SearchDropdown } from '$/components/core/Collection/MaterialSearch/SearchDropdown';
import { useMaterialSearchStore } from '$/components/core/Collection/MaterialSearch/store/useMaterialSearchStore';
import { useIntersectionObserver } from '$/hooks/useIntersectionObserver';
import { useIsMobile } from '$/hooks/useIsMobile';
import { BackToTopButton } from '$/pages/DashboardPages/components/BackToTopButton';
import { BackButton } from '$/pages/DashboardPages/pages/Collection/pages/components/BackButton';
import { StickySubCollectionHeader } from '$/pages/DashboardPages/pages/Collection/pages/components/StickySubCollectionHeader';
import { SubCollectionColorHeader } from '$/pages/DashboardPages/pages/Collection/pages/components/SubCollectionColorHeader';
import { SubCollectionFacadeHeader } from '$/pages/DashboardPages/pages/Collection/pages/components/SubCollectionFacadeHeader';
import { SubCollectionFloorHeader } from '$/pages/DashboardPages/pages/Collection/pages/components/SubCollectionFloorHeader';
import { SubCollectionWallsHeader } from '$/pages/DashboardPages/pages/Collection/pages/components/SubCollectionWallsHeader';
import { FavoriteModal } from '$/pages/DashboardPages/pages/Favorite/components/FavoriteModal';
import { FilterType, MaterialType } from '$/services/mapper/uses';

interface Props {
  children: ReactNode;
  filterType: FilterType;
  materialType: MaterialType;
}

export const BaseCollectionLayout = forwardRef<HTMLDivElement, Props>(
  function BaseCollectionLayout({ children, filterType, materialType }, ref) {
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const setActiveMaterial = useMaterialDetailStore.useSetActive();
    const query = useMaterialSearchStore.useQuery();

    const [isSticky, setIsSticky] = useState(false);

    const controlRef = useIntersectionObserver(
      (intersecting) => setIsSticky(!intersecting),
      { threshold: 0.5 },
    );

    return (
      <Stack
        {...(!isMobile && { layerStyle: 'DashboardSection' })}
        pos='relative'
        flex='1'
        overflow='hidden'
      >
        <StyledScrollWrapper>
          <StickySubCollectionHeader
            px='7'
            py='1'
            top={isSticky ? '0' : '-20'}
            onActionClick={() => {
              setIsSticky(false);
              if (ref && typeof ref === 'object' && ref.current) {
                ref.current.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
              }
            }}
          />
          <Box ref={ref} overflow='auto'>
            <Stack px={isMobile ? '0' : '7'} pt={isMobile ? '0' : '5'} pb='5'>
              <Stack ref={controlRef}>
                <Stack>
                  <BackButton />
                  <HStack
                    justify='space-between'
                    flexWrap={{ base: 'wrap', mobile: 'nowrap' }}
                    w='full'
                  >
                    {filterType === 'uni' && <SubCollectionColorHeader />}
                    {filterType === 'wall' && <SubCollectionWallsHeader />}
                    {filterType === 'floor' && <SubCollectionFloorHeader />}
                    {filterType === 'facade' && <SubCollectionFacadeHeader />}
                    <SearchDropdown
                      maxW='275px'
                      w='100%'
                      display={{ base: 'none', mobile: 'block' }}
                      searchLabel={t('dashboard.collection.searchColors')}
                      onResultClick={(material) => setActiveMaterial(material)}
                    />
                  </HStack>
                </Stack>

                {children}
              </Stack>

              <MaterialPresentation
                type={materialType}
                scrollRefs={[ref]}
                onSelect={setActiveMaterial}
                query={query}
              />
            </Stack>
          </Box>

          <BackToTopButton
            containerRef={ref}
            right='32px'
            bottom='32px'
          ></BackToTopButton>
          <FavoriteModal />
        </StyledScrollWrapper>
      </Stack>
    );
  },
);
