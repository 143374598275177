import { createRoute, redirect } from '@tanstack/react-router';
import { memo } from 'react';

import { useMaterialSearchStore } from '$/components/core/Collection/MaterialSearch/store/useMaterialSearchStore';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';
import { InspirationEditorPage } from '$/pages/InspirationEditorPage';
import {
  Layer,
  useInspirationEditorStore,
} from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { RootRoute } from '$/routes/RootRoute';
import { filterOptionsQuery } from '$/services/usecases/filter/queries';
import { materialsQuery } from '$/services/usecases/materials/queries';
import { completeSceneQuery } from '$/services/usecases/scenes/queries';
import { useCollectionStore, initial } from '$/stores/useCollectionStore';
import { useFavoriteStore } from '$/stores/useFavoriteStore';
import { useLastSeenStore } from '$/stores/useLastSeenStore';
import { useMaterialStore } from '$/stores/useMaterialStore';

const RouteComponent = memo(() => <InspirationEditorPage />);
RouteComponent.displayName = 'InspirationEditorPage';

export const InspirationsEditorRoute = createRoute({
  getParentRoute: () => RootRoute,
  path: '/inspirations/$id',
  component: RouteComponent,
  onLeave: () => {
    useCollectionStore.setState({
      filterGroups: initial.filterGroups,
      brightnessFilter: initial.brightnessFilter,
    });
    useInspirationEditorStore.setState({
      activeSceneVariantIndex: 0,
      sceneLayersVariants: [],
      activeViewIndex: 0,
      activeComponentId: null,
      viewIds: [],
    });
    useMaterialStore.setState({ activeMaterialId: null });
    useFavoriteStore.setState({ activeFolderId: null });
  },
  onEnter: () => {
    useMaterialSearchStore.setState({ query: '', dropdownVisible: false });
  },
  loader: async ({ params, context: { client } }) => {
    try {
      useCollectionStore.setState({ filter: undefined });
      useEditorStore.setState({ isFullScreen: false });
      useInspirationEditorStore.setState({ perspectiveFullscreen: false });
      await client.ensureQueryData(filterOptionsQuery()).then((filter) => {
        useCollectionStore.setState({ filter });
      });

      const loadComponents = async () => {
        const { activeMaterialId } = useMaterialStore.getState();
        const state = useInspirationEditorStore.getState();
        useCollectionStore.setState({ activeType: 'all' });

        state.setIsLoading(true);

        const scene = await client.ensureQueryData(
          completeSceneQuery(params.id),
        );

        if (!scene) {
          throw redirect({ to: '/not-found' });
        }

        let components = scene.views.reduce<Layer[]>((acc, view) => {
          view.components.forEach((component) => {
            const index = acc.findIndex((c) => c.id === component.id);
            if (index === -1) {
              acc.push({ ...component, views: [view.viewNr] });
            } else {
              acc[index].views.push(view.viewNr);
            }
          });
          return acc;
        }, []);

        if (state.sceneLayersVariants.length === 0) {
          if (activeMaterialId) {
            const activeMaterialData = await client.ensureQueryData(
              materialsQuery({ ids: [activeMaterialId] }),
            );

            const activeMaterial = activeMaterialData.materials[0];

            components = components.map((component) => {
              const wantsToApplyColor =
                component.materialTypes.includes('uni') &&
                activeMaterial.type.includes('uni');

              const wantsToApplyWall =
                component.materialTypes.includes('wall') &&
                activeMaterial.type.includes('wall');

              const materialApplicable =
                wantsToApplyColor || wantsToApplyWall
                  ? component.category === 'wall' ||
                    component.category === 'outside'
                  : component.materialTypes.some((type) =>
                      activeMaterial.type.includes(type),
                    );

              const material = materialApplicable
                ? activeMaterial
                : component.material;

              return { ...component, material };
            });
          }

          state.setSceneLayersVariants([components]);
        }

        useLastSeenStore.getState().addLastScene(scene.id);
        useInspirationEditorStore.setState({
          isLoading: false,
          layerMarkers: components,
          completeScene: scene,
          scene: { ...scene, views: scene.views.map((_, i) => i.toString()) },
          viewIds: scene.views.map((view) => view.viewId),
        });

        return useInspirationEditorStore.getState().getActiveSceneVariant();
      };

      await loadComponents();
    } catch (error) {
      console.error('Error loading inspiration scene:', error);
      throw redirect({ to: '/not-found' });
    }
  },
  staleTime: 0,
});
